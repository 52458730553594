import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "reactstrap";
import Home from "./components/Home";
import useToken from "./components/login/useToken";
import Topbar from "./components/Topbar";
import Event from "./components/event/Event";
import Questions from "./components/questions/Questions";
import Question from "./components/questions/Question";
import Presenter from "./components/presenter/Presenter";
import ApiContext from "./components/ApiContext";
import Socket from "./context/Socket";

import "./App.css";
import Rounds from "./components/Rounds";
import { useEffect, useState } from "react";
import Final from "./components/final/Final";
import Summary from "./components/Summary";
import Custom from "./components/Custom";

function App() {
	const { token, setToken } = useToken();
	const [socketConnected, setSocketConnected] = useState(Socket.connected);

	useEffect(() => {
		const handleConnect = () => setSocketConnected(Socket.connected);
		const handleDisconnect = () => setSocketConnected(Socket.connected);
		Socket.on("connect", handleConnect);
		Socket.on("disconnect", handleDisconnect);
		return () => {
			Socket.off("connect", handleConnect);
			Socket.off("disconnect", handleDisconnect);
		};
	}, []);

	if (!socketConnected) return <h1>Connecting to socket...</h1>;

	return (
		<ApiContext.Provider value={token}>
			<Router>
				<Topbar setToken={setToken} />
				<Container>
					<Routes>
						<Route path="/questions" element={<Questions />} />
						<Route path="/" exact element={<Home />} />
						<Route path="/question/:id" element={<Question />} />
						<Route path="/event/:id" element={<Event />} />
						<Route path="/custom/:id" element={<Custom />} />
						<Route path="/rounds/:id" element={<Rounds />} />
						<Route path="/summary/:id" element={<Summary />} />
						<Route path="/presenter/:id" exact element={<Presenter />} />
						<Route path="/presenter/final/:id" exact element={<Final />} />
					</Routes>
				</Container>
			</Router>
		</ApiContext.Provider>
	);
}

export default App;
