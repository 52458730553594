import React, { useState, useEffect, useContext, useCallback } from "react";
import {
	Button,
	Col,
	Row,
	Card,
	CardHeader,
	CardBody,
	Input,
	Label,
} from "reactstrap";
import ApiContext from "../ApiContext";
import socket from "../../context/Socket";
import { useParams } from "react-router";
import { swalText } from "../Swal";

function Final() {
	const token = useContext(ApiContext);
	const [data, setData] = useState(false);
	const [roomId, setRoomId] = useState();
	const [clockCountdown, setClockCountdown] = useState(45);
	const { id } = useParams();

	const loadData = useCallback(
		async (body = {}) => {
			var res = await fetch(
				`https://surveysays.myactivityhub.net/api.php?presenterFinal=${id}`,
				{
					method: "POST",
					headers: new Headers({
						Authorization: token,
					}),
					body: JSON.stringify(body),
				}
			);
			res = await res.json();
			setRoomId(`game-${res.game_hash}`);
			setData(res);
			return "banana";
		},
		[token, id]
	);

	useEffect(() => {
		loadData();
	}, [loadData, id]);

	useEffect(() => {
		if (!roomId) return; // No room to join yet
		socket.joinRoom(roomId);
		return () => socket.joinRoom(`none`);
	}, [roomId]);

	var server_event = useCallback(
		async (body = {}) => {
			await fetch(
				`https://surveysays.myactivityhub.net/api.php?presenterFinal=${id}`,
				{
					method: "POST",
					headers: new Headers({
						Authorization: token,
					}),
					body: JSON.stringify(body),
				}
			);
			loadData();
		},
		[id, token, loadData]
	);

	async function clock(type, data) {
		switch (type) {
			case "start":
				socket.emit("countdown", { seconds: data.seconds });
				break;

			case "stop":
				socket.emit("stopclock");
				break;

			default:
				break;
		}
	}

	const setAnswer = async (answer_id, is_top_answer, fq_id) => {
		await server_event({
			action: "final_answer",
			answer_id,
			is_top_answer,
			fq_id,
		});
		socket.emit("final_answer");
	};

	const wrongAnswer = async (fq_id) => {
		var swal = await swalText({ title: "Please enter wrong answer" });
		if (!swal.value) return;
		setAnswer(0, 0, fq_id);
		await server_event({
			action: "wrong_answer",
			fq_id,
			answer: swal.value,
		});
	};

	const showQuestion = async (question_id) => {
		await server_event({
			action: "show_question",
			value: question_id,
			game_id: id,
		});
		socket.emit("show_question");
	};

	const reveal = async (type, fq_id, points, revealed) => {
		await server_event({
			action: type === "answer" ? "reveal" : "reveal_points",
			value: fq_id,
			game_id: id,
		});
		if (type === "points") socket.emit("reveal_points", { points, revealed });
		else socket.emit("reveal");
	};
	const doublePoints = async (type, fq_id, points) => {
		await server_event({ action: "double" });
		socket.emit("final_payout");
	};

	if (!data) return <h1>Loading ...</h1>;
	return (
		<div style={{ color: "white" }}>
			<h1>
				Presenter - Double Bubble
				<br />
				{!!data.question_id && (
					<Button
						color="warning"
						className="float-right"
						onClick={() => showQuestion(0)}
					>
						<i className="fa fa-eye-slash"></i> Hide Question
					</Button>
				)}
			</h1>
			<Label style={{ fontSize: "1.2em" }} className="mr-1">
				Clock Time:{" "}
			</Label>
			<Input
				style={{ width: "200px", display: "inline" }}
				type="select"
				name="select"
				id="exampleSelect"
				onChange={(e) => setClockCountdown(e.target.value)}
			>
				<option value={15}>15 Seconds</option>
				<option value={30}>30 Seconds</option>
				<option selected value={45}>
					45 Seconds
				</option>
				<option value={60}>60 Seconds</option>
			</Input>
			<Row>
				{data.teams.map((t) => {
					return (
						<Col key={t.team_id} md="6">
							<Card color="dark">
								<CardHeader tag="h5" style={{ color: "white" }}>
									<i
										style={{ color: t.team_colour }}
										className="fa fa-circle"
									></i>{" "}
									{t.team_name}
									<div className="float-right">
										<i className="fa fa-coins"></i> {t.team_score}
									</div>
								</CardHeader>
								<Button
									onClick={() => clock("start", { seconds: clockCountdown })}
									className="btn-block"
									color="primary"
								>
									Start The Clock ({clockCountdown})
								</Button>
								<CardBody style={{ color: "white" }}>
									{t.questions.map((q) => {
										var answerIcon = q.revealed_answer
											? "fa fa-eye"
											: "fa fa-eye-slash";
										var pointsIcon = q.revealed_points
											? "fa fa-eye"
											: "fa fa-eye-slash";
										var questionActive = q.question_id === data.question_id;
										return (
											<>
												<h5
													style={
														questionActive
															? { color: "#66ff66", cursor: "pointer" }
															: { cursor: "pointer" }
													}
													onClick={() => showQuestion(q.question_id)}
												>
													{questionActive && (
														<>
															<i className="fa fa-eye"></i>&nbsp;
														</>
													)}
													{q.question_text}
												</h5>
												<br />
												{q.answers.map((a) => {
													var selected = q.answer_id === a.answer_id;
													return (
														<h6
															onClick={() =>
																setAnswer(a.answer_id, a.is_top_answer, q.fq_id)
															}
															style={
																selected
																	? { color: "#66ff66", cursor: "pointer" }
																	: { cursor: "pointer" }
															}
														>
															- {a.answer_text}
															{selected && (
																<>
																	&nbsp;<i className="fa fa-check-circle"></i>
																</>
															)}
														</h6>
													);
												})}
												<h6
													onClick={() => wrongAnswer(q.fq_id)}
													style={{ cursor: "pointer" }}
													className="mt-3 mb-3"
												>
													Wrong Answer
													{q.wrong_answer && (
														<b style={{ color: "red" }}>
															{" "}
															({q.wrong_answer}){" "}
															<i className="fa fa-check-circle"></i>
														</b>
													)}
												</h6>

												{(q.answer_id > 0 || q.wrong_answer) && (
													<Button
														onClick={() => setAnswer(0, 0, q.fq_id)}
														style={{ cursor: "pointer" }}
														className="float-right btn-sm"
													>
														<i className="fa fa-times"></i> Clear Answer
													</Button>
												)}

												<Button
													onClick={() =>
														reveal(
															"answer",
															q.fq_id,
															q.answer_points,
															q.revealed_answer
														)
													}
													className="btn-sm mr-1"
												>
													<i className={answerIcon}></i> Reveal Answer
												</Button>
												<Button
													onClick={() =>
														reveal(
															"points",
															q.fq_id,
															q.answer_points,
															q.revealed_points
														)
													}
													className="btn-sm mr-1"
												>
													<i className={pointsIcon}></i> Reveal Points
												</Button>
												<hr />
											</>
										);
									})}
									{/* <pre>
										{JSON.stringify(t, null, 2)}
									</pre> */}
								</CardBody>
								<Button
									onClick={() => clock("stop")}
									className="btn-block"
									color="danger"
								>
									Stop the Clock
								</Button>
							</Card>
						</Col>
					);
				})}
			</Row>
			<Card color="dark" className="mt-4">
				<CardBody>
					<Button
						className={"btn-block " + (data.final_doubled ? "disabled" : "")}
						onClick={() => {
							if (!data.final_doubled) doublePoints();
						}}
						color="info"
					>
						Double The Points
					</Button>
				</CardBody>
			</Card>
		</div>
	);
}

export default Final;
