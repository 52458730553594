let listeners = [];
const socket = {
	current: null,
	roomName: "default",
	emit: (eventName, data) =>
		socket.current.send(JSON.stringify({ ...data, eventName })),
	on: (eventName, listener) => {
		listener.eventName = eventName;
		listeners.push(listener);
	},
	off: (listener) => (listeners = listeners.filter((l) => l !== listener)),
	joinRoom: (roomName) => {
		socket.roomName = roomName;
		if (socket.current.readyState === 1)
			socket.emit(null, { join_room: roomName });
	},
	connected: false,
};

const socket_connect = () => {
	socket.current = new WebSocket("wss://live.myactivityhub.net");
	socket.current.onmessage = (m) => {
		m = JSON.parse(m.data);
		listeners.forEach((l) => {
			if (l.eventName === m.eventName) l(m);
		});
	};
	socket.current.onclose = () => {
		socket.connected = false;
		socket.current.onmessage({
			data: JSON.stringify({ eventName: "disconnect" }),
		});
		setTimeout(socket_connect, 3000);
	};
	socket.current.onerror = (error) =>
		console.log(`Socket error`, error.message);
	socket.current.onopen = () => {
		socket.connected = true;
		socket.current.onmessage({
			data: JSON.stringify({ eventName: "connect" }),
		});
		socket.emit(null, { join_room: socket.roomName });
	};
};
socket_connect();

export default socket;

/*
import io from "socket.io-client";

// Going to try loading here
console.log("SOCKET CONNECTING...");
const newSocket = io("https://www.murderbymidnight.online:3333");
newSocket.on("connect_error", (err) => console.log(err));
newSocket.on("connect_failed", (err) => console.log(err));
newSocket.on("disconnect", (d) => console.log("disconnect " + d));
newSocket.on("connect", () => {
	console.log("SOCKET CONNECTED TO BLUEHAT");
});

// setTimeout(()=>newSocket.close(), 7000);
export default newSocket;
*/
