import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
	Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavItem,
} from 'reactstrap'


function Topbar({setToken}) {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);
	return (
		<Navbar color="dark" dark expand="md">
			<Link to='/' className="navbar-brand"> Our Survey Says </Link>
			<NavbarToggler onClick={toggle} />
			<Collapse isOpen={isOpen} navbar>
				<Nav className="mr-auto" navbar>
					<NavItem>
						<Link to='/' className="nav-link">
							<i className='fa fa-home'></i> Home
						</Link>
					</NavItem>
					<NavItem>
						<Link to='/questions' className="nav-link">
							<i className='fa fa-question'></i> Questions
						</Link>
					</NavItem>
					
					<NavItem>
						<a href='#signout' className="nav-link" onClick={(e) => {e.preventDefault(); setToken('')}}>
							<i className='fa fa-power-off'></i> Sign Out
						</a>
					</NavItem>
				</Nav>
			</Collapse>
		</Navbar>
	)

}

export default Topbar;