import { useEffect, useState, useContext, useCallback } from "react";
import { Card, Button, CardBody, Input } from "reactstrap";
import { swal, swalConfirm, swalText } from "../Swal";
import useToken from "../login/useToken";
import Login from "../login/Login";
import { useNavigate } from "react-router-dom";

const Questions = () => {
	const { token, setToken } = useToken();
	const navigate = useNavigate();
	const [questions, setQuestions] = useState([]);
	const [showingQuestions, setShowingQuestions] = useState([]);
	const [search, setSearch] = useState("");

	// Search box filtering
	useEffect(() => {
		setShowingQuestions(
			questions.filter((q) =>
				q.question_text.toLowerCase().includes(search.toLowerCase())
			)
		);
	}, [search, questions]);

	// Initially load data
	const loadQuestions = useCallback(async () => {
		try {
			const res = await fetch(
				`https://surveysays.myactivityhub.net/api.php?questions`,
				{
					headers: new Headers({
						Authorization: token,
					}),
				}
			);
			const data = await res.json();
			setQuestions(data);
			setShowingQuestions(data);
		} catch (error) {
			console.error("Error loading questions:", error);
		}
	}, [token]);

	useEffect(() => {
		loadQuestions();
	}, [loadQuestions]);

	if (!token) return <Login setToken={setToken} />;

	const archiveQuestion = async (question_id) => {
		try {
			const res = await fetch(
				`https://surveysays.myactivityhub.net/api.php?archiveQuestion=${question_id}`,
				{
					headers: new Headers({
						Authorization: token,
					}),
				}
			);
			if (res.ok) {
				loadQuestions();
				swal({ title: "Question archived" });
			}
		} catch (error) {
			console.error("Error archiving question:", error);
		}
	};

	const addQuestion = async () => {
		const question = await swalText({ title: "Please enter your question" });
		if (!question.value) return;
		try {
			let res = await fetch(
				`https://surveysays.myactivityhub.net/api.php?addQuestion`,
				{
					headers: new Headers({
						Authorization: token,
					}),
					method: "POST",
					body: JSON.stringify({ question: question.value }),
				}
			);
			if (res.ok) {
				res = await res.json();
				if (res?.question) navigate(`/question/${res.question}`);
			}
		} catch (error) {
			console.error("Error adding question:", error);
		}
	};

	if (!questions.length) return <h1>Loading Questions...</h1>;

	return (
		<>
			<h1 className="my-4">
				<i className="fa fa-question mr-2"></i>
				Questions
				<i
					onClick={addQuestion}
					style={{ cursor: "pointer" }}
					className="fa fa-plus-circle float-right mr-4 mt-2"
				></i>
			</h1>
			<Input
				onChange={(e) => setSearch(e.target.value)}
				className="mb-1"
				placeholder="Search ..."
			/>
			{showingQuestions.map((q) => (
				<Card
					key={q.question_id}
					color="dark"
					className="mb-1"
					style={{ color: "white" }}
				>
					<CardBody onClick={() => navigate(`/question/${q.question_id}`)}>
						<h5>
							{q.question_text}
							<Button
								onClick={async (e) => {
									e.stopPropagation();
									if (
										await swalConfirm({
											text: `Archive question ${q.question_id}`,
										})
									)
										archiveQuestion(q.question_id);
								}}
								className="btn-sm float-right"
							>
								<i className="fa fa-archive"></i>
							</Button>
						</h5>
					</CardBody>
				</Card>
			))}
		</>
	);
};

export default Questions;
