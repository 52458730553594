import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Input, Button, FormGroup, Label } from "reactstrap";
import ApiContext from "./ApiContext";

const Custom = () => {
	const { id } = useParams();
	const token = useContext(ApiContext);
	const [event, setEvent] = useState(null);
	const [file, setFile] = useState(null);
	const navigate = useNavigate();

	const request = useCallback(
		async (func, data = {}) => {
			try {
				let res = await fetch(
					`https://surveysays.myactivityhub.net/api.php?custom=${id}&${func}`,
					{
						method: "POST",
						headers: new Headers({
							Authorization: token,
							"Content-Type": "application/json",
						}),
						body: JSON.stringify(data),
					}
				);
				res = await res.json();
				return res;
			} catch (error) {
				console.error("Error fetching data:", error);
				return null;
			}
		},
		[id, token]
	);

	useEffect(() => {
		request("getCustom").then((res) => {
			setEvent(res);
		});
	}, [request]);

	const updateTitle = (value) => {
		setEvent((prev) => ({ ...prev, event_final_title: value }));
	};

	const updateTheme = (value) => {
		setEvent((prev) => ({ ...prev, event_theme: value }));
	};

	const updateImage = (image) => {
		setFile(image[0]);
	};

	const save = () => {
		const formData = new FormData();
		formData.append("event", event);
		formData.append("branding", file);

		fetch(
			`https://surveysays.myactivityhub.net/api.php?custom=${id}&save&title=${
				event.event_final_title ?? ""
			}&theme=${event.event_theme ?? ""}`,
			{
				method: "POST",
				body: formData,
			}
		)
			.then((response) => response.json())
			.then(() => {
				navigate(`/event/${id}`);
			})
			.catch((error) => {
				console.error("Error saving data:", error);
			});
	};

	if (!event) return <h1>Loading...</h1>;

	return (
		<div>
			<Link to={`/event/${id}`}>
				<Button className="float-right">
					<i className="fa fa-arrow-left"></i> Return
				</Button>
			</Link>
			<h2>{event.event_name}</h2>
			<hr />

			<h4>Double Bubble Title</h4>
			<Input
				className="mb-3"
				type="text"
				value={event.event_final_title}
				onChange={(e) => updateTitle(e.target.value)}
			/>

			<h4>Event Theme</h4>
			<FormGroup>
				<Label for="themeSelect">Select</Label>
				<Input
					id="themeSelect"
					value={event.event_theme ?? ""}
					type="select"
					onChange={(e) => updateTheme(e.target.value)}
				>
					<option value="">Default</option>
					<option value="XMAS">Christmas</option>
				</Input>
			</FormGroup>

			<h4>Branding</h4>
			<Input
				className="mb-3"
				type="file"
				accept="image/*"
				onChange={(e) => updateImage(e.target.files)}
			/>
			{event.event_branding && (
				<img
					style={{ maxWidth: "900px", maxHeight: "280px" }}
					className="mb-3"
					src={`https://bluehat-teambuilding.s3.eu-west-2.amazonaws.com/our_survey_says/custom_branding/${event.event_branding}`}
					alt="Event Branding"
				/>
			)}

			<Button onClick={save} className="btn-block">
				Save and Exit
			</Button>
		</div>
	);
};

export default Custom;
