import { useState, useEffect } from "react";
import "./Login.css";
import {
	Button,
	Input,
	Alert,
	Container,
	Form,
	FormGroup,
	Label,
	CardBody,
	CardTitle,
	Card,
} from "reactstrap";

async function loginUser(credentials) {
	return fetch("https://surveysays.myactivityhub.net/api.php?login", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(credentials),
	})
		.then((data) => data.json())
		.then((res) => {
			if (res.status === "success") return res.token;
			else return null;
		});
}

const Login = ({ setToken }) => {
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [status, setStatus] = useState();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setStatus("signing");
		if (!email || !password) return setStatus("blank");
		const token = await loginUser({
			email: email,
			password: password,
		});

		if (!token) return setStatus("wrong");

		setToken(token);
	};

	const set = (func, val) => {
		setStatus();
		func(val);
	};

	useEffect(() => {
		document.getElementById("email").focus();
	}, []);

	return (
		<Container>
			<Form className="login-form" onSubmit={handleSubmit}>
				<h1> Our Survey Says </h1>
				<hr />
				<Card color="dark">
					<CardBody>
						<CardTitle tag="h5">Please sign in</CardTitle>
						{status === "wrong" && (
							<Alert color="danger">Email or password incorrect</Alert>
						)}
						{status === "blank" && (
							<Alert color="danger">Email or password is blank</Alert>
						)}
						<FormGroup>
							<Label for="email"> Email Address</Label>
							<Input
								type="email"
								id="email"
								value={email}
								onChange={(e) => set(setEmail, e.target.value)}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="password"> Password </Label>
							<Input
								type="password"
								id="password"
								value={password}
								onChange={(e) => set(setPassword, e.target.value)}
							/>
						</FormGroup>
						<Button type="submit" color="info" block={true}>
							{status === "signing" ? "Signing..." : "Sign In"}
						</Button>
					</CardBody>
				</Card>
			</Form>
		</Container>
	);
};

export default Login;
